import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  inject,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  IDatatablePageChangeArgs,
  IDatatableRowActivateArgs,
  IDesignageDataTableColumns,
  IPlaylistSaveDialogResult,
} from '@desquare/interfaces';
import {
  DatatableRowActivationType,
  Permissions,
  PlaylistSaveDialogCloseReason,
} from '@desquare/enums';
import {
  Maybe,
  GetLocationPlaylistsGQL,
  GetLocationPlaylistsQuery,
  GetLocationPlaylistsQueryVariables,
  GetChannelGroupPlaylistsGQL,
  GetChannelGroupPlaylistsQuery,
  GetChannelGroupPlaylistsQueryVariables,
  GetProfilePlaylistsGQL,
  GetProfilePlaylistsQuery,
  GetProfilePlaylistsQueryVariables,
  GetChannelPlaylistsGQL,
  GetChannelPlaylistsQuery,
  GetChannelPlaylistsQueryVariables,
  PlaylistForPlaylistListFragment,
  Playlist,
  PlaylistStatus,
  DuplicatePlaylistsGQL,
  BulkActionPlaylistsGQL,
  PlaylistBulkAction,
  PlaylistType,
} from '@designage/gql';
import { SubSink } from 'subsink';
import {
  EncryptionService,
  CurrentUserService,
  ToasterService,
  FilterService,
  SlidePanelService,
  SessionService,
  CloudinaryService,
  PlaylistEditorService,
  PlaylistService,
} from '@desquare/services';
import { Router, RouterOutlet } from '@angular/router';
import { ApolloError } from '@apollo/client/errors';
import {
  NgbModal,
  NgbPopoverModule,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { PlaylistPublishDialogComponent } from '../playlist-publish-dialog/playlist-publish-dialog.component';
import { domConstants } from '@desquare/constants';
import { uniqBy } from 'lodash';
import { DeletePlaylistDialogComponent } from '../delete-playlist-dialog/delete-playlist-dialog.component';
import { QueryRef } from 'apollo-angular';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';
import { bulkActionsUtil } from '@desquare/utils';
import { TranslateModule } from '@ngx-translate/core';
import { TableDateTimeComponent } from '@desquare/components/common/src/designage-data-table/table-components/table-dateTime.component';
import { PlaylistsStore } from '@desquare/stores';
import { NgClass } from '@angular/common';
import { CloudinaryMediaComponent } from '@desquare/components/common/src/cloudinary/cloudinaryMedia.component';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    TranslateModule,
    NgbPopoverModule,
    NgbTooltip,
    DesignageDataTableComponent,
    TableDateTimeComponent,
    RouterOutlet,
    CloudinaryMediaComponent,
  ],
  selector: 'app-playlist-list',
  templateUrl: './playlist-list.component.html',
  styleUrls: ['./playlist-list.component.scss'],
})
export class PlaylistListComponent implements OnInit, OnDestroy {
  playlistsStore = inject(PlaylistsStore);
  playlistsSignal = this.playlistsStore.playlists;
  playlistService = inject(PlaylistService);
  loading = this.playlistsStore.loading;
  private subs = new SubSink();

  @ViewChild(DesignageDataTableComponent)
  designageDataTable!: DesignageDataTableComponent;

  @Input() channelId?: Maybe<string>;
  @Input() locationId?: Maybe<string>;
  @Input() channelGroupId?: Maybe<string>;
  @Input() showAddPlaylistButton = true;
  @Input() showHeader = true;

  @Input() showBulkActionControl = true;
  @Input() redirectOnSelect = true;
  /** false: single select, true: multi select */
  @Input() enableCheckbox = true;
  /** allow selection, single or multi */
  @Input() enablePlaylistSelect = true;
  @Input() enableSlidePanel = true;

  @Input() hidePlaylistIds: string[] = [];
  @Output() playlistsSelect = new EventEmitter<Playlist[]>();

  slidePanelService = inject(SlidePanelService);
  loaderMessage!: string;
  datatableLoading = false;
  desColumns!: IDesignageDataTableColumns[];
  playlists: Playlist[] = [];
  selectedChannels: PlaylistForPlaylistListFragment[] = [];
  total = 0;
  searchText = '';
  page = 1;
  pageSize = 10;
  pageSizeOptions = domConstants.DATA_PAGE_SIZE_OPTIONS;
  playlistListActions = Object.values(PlaylistBulkAction);
  PlaylistType = PlaylistType;
  statuses: PlaylistStatus[] = [
    PlaylistStatus.Draft,
    PlaylistStatus.Published,
    PlaylistStatus.ReadyToPublish,
  ];

  profileId!: Maybe<string>;
  selectedPlaylists: PlaylistForPlaylistListFragment[] = [];
  bulkActionsUtil = bulkActionsUtil;

  // slide panel variables
  currentPlaylistId?: string;

  // query references
  getLocationPlaylistsQueryRef?: QueryRef<
    GetLocationPlaylistsQuery,
    GetLocationPlaylistsQueryVariables
  >;
  getChannelGroupPlaylistsQueryRef?: QueryRef<
    GetChannelGroupPlaylistsQuery,
    GetChannelGroupPlaylistsQueryVariables
  >;
  getProfilePlaylistsQueryRef?: QueryRef<
    GetProfilePlaylistsQuery,
    GetProfilePlaylistsQueryVariables
  >;
  getChannelPlaylistsQueryRef?: QueryRef<
    GetChannelPlaylistsQuery,
    GetChannelPlaylistsQueryVariables
  >;

  get isSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }

  constructor(
    private playlistEditorService: PlaylistEditorService,
    private getProfilePlaylistsGQL: GetProfilePlaylistsGQL,
    private encryptionService: EncryptionService,
    private currentUserService: CurrentUserService,
    private router: Router,
    private toasterService: ToasterService,
    private session: SessionService,
    private getChannelPlaylistsGQL: GetChannelPlaylistsGQL,
    private getLocationPlaylistsGQL: GetLocationPlaylistsGQL,
    private getChannelGroupPlaylistsGQL: GetChannelGroupPlaylistsGQL,
    private duplicatePlaylistsGQL: DuplicatePlaylistsGQL,
    private modalService: NgbModal,
    private bulkActionPlaylists: BulkActionPlaylistsGQL,
    private filterService: FilterService,
    public cloudinaryService: CloudinaryService,
  ) {}
  isSlidePanelOpen = this.slidePanelService.getIsPanelOpen();
  selectedPlaylistId = this.slidePanelService.getPanelComponentId();
  get hasNoData(): boolean {
    return !this.loading && this.total === 0;
  }

  get canAddPlaylists() {
    return (
      this.currentUserService.hasPermissions([
        Permissions.CREATE_ANYTHING,
        Permissions.CREATE_PLAYLISTS,
      ]) &&
      !this.channelId &&
      !this.locationId &&
      !this.channelGroupId
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.initVariables();
    this.initSubscriptions();
  }

  initVariables() {
    this.profileId = this.session.profileId();
    this.desColumns = [
      {
        fieldName: 'previewContent',
        name: 'PREVIEW',
        type: 'template',
        templateRef: 'playlistPreview',
        visible: true,
        style: 'max-width:50px;justify-content: center;',
        childComponentStyle:
          'justify-content: center !important; height: 50px;',
        disableSorting: true,
      },
      {
        fieldName: 'name',
        name: 'NAME',
        type: 'string',
        visible: 'mandatory',
        flex: '2',
      },
      {
        fieldName: 'updatedAt',
        name: 'LAST_UPDATED',
        type: 'template',
        templateRef: 'updatedAt',
        visible: true,
      },
      {
        fieldName: 'createdAt',
        name: 'CREATED_ON',
        type: 'template',
        templateRef: 'createdAt',
        visible: true,
      },
      {
        fieldName: 'status',
        name: 'STATUS',
        type: 'template',
        templateRef: 'playlistStatus',
        visible: true,
        groupable: true,
      },
    ];

    this.pageSize = this.currentUserService.preferredPageSize;
  }

  initSubscriptions() {
    this.playlistEditorService.playlistSaveChanges.subscribe((value) => {
      if (value) {
        this.getPlaylists();
      }
    });
    this.getPlaylists();
  }

  getPlaylists() {
    this.datatableLoading = true;
    this.loaderMessage = 'FETCHING_ASSETS';
    this.playlists = [];

    if (this.channelId) {
      this.getChannelPlaylists();
    } else if (this.channelGroupId) {
      this.getChannelGroupPlaylists();
    } else if (this.locationId) {
      this.getLocationPlaylists();
    } else {
      // TODO: channel-user-playlist assignment is obsolete, use resource groups
      // OLD: Uncomment this once the channel-user-playlist assignment is enabled.
      this.getProfilePlaylists();
      // } else {
      //   this.getProfileUserChannelPlaylists();
      // }
    }
  }

  getChannelPlaylists() {
    if (this.channelId) {
      this.getChannelPlaylistsQueryRef = this.getChannelPlaylistsGQL.watch(
        {
          channelId: this.channelId,
          statuses: this.statuses,
        },
        { fetchPolicy: 'cache-and-network' },
      );

      this.subs.sink = this.getChannelPlaylistsQueryRef.valueChanges.subscribe({
        next: ({ loading, data }) => {
          const { channel } = data;
          this.datatableLoading = loading;

          const filteredPlaylist = this.filterService.filterListByName(
            this.searchText,
            channel?.playlists || [],
          );

          this.total = filteredPlaylist.length;
          this.playlists = filteredPlaylist;
        },
        error: (err) => {
          this.datatableLoading = false;
        },
      });
    } else {
      this.datatableLoading = false;
    }
  }

  getChannelGroupPlaylists() {
    if (this.channelGroupId) {
      this.getChannelGroupPlaylistsQueryRef =
        this.getChannelGroupPlaylistsGQL.watch(
          {
            channelGroupId: this.channelGroupId,
            statuses: this.statuses,
          },
          { fetchPolicy: 'cache-and-network' },
        );

      this.subs.sink =
        this.getChannelGroupPlaylistsQueryRef.valueChanges.subscribe({
          next: ({ loading, data }) => {
            const { channelGroup } = data;
            this.datatableLoading = loading;

            const filteredPlaylist = this.filterService.filterListByName(
              this.searchText,
              channelGroup?.playlists || [],
            );

            this.total = filteredPlaylist.length;
            this.playlists = filteredPlaylist;
          },
          error: (err) => {
            this.datatableLoading = false;
          },
        });
    } else {
      this.datatableLoading = false;
    }
  }

  getLocationPlaylists() {
    if (this.locationId) {
      this.getLocationPlaylistsQueryRef = this.getLocationPlaylistsGQL.watch(
        {
          locationId: this.locationId,
          statuses: this.statuses,
        },
        { fetchPolicy: 'cache-and-network' },
      );

      this.subs.sink = this.getLocationPlaylistsQueryRef.valueChanges.subscribe(
        {
          next: ({ loading, data }) => {
            const { location } = data;
            this.datatableLoading = loading;

            const filteredPlaylist = this.filterService.filterListByName(
              this.searchText,
              location?.playlists || [],
            );

            this.total = filteredPlaylist.length;
            this.playlists = filteredPlaylist;
          },
          error: (err) => {
            this.datatableLoading = false;
          },
        },
      );
    } else {
      this.datatableLoading = false;
    }
  }

  getProfilePlaylists() {
    if (this.profileId) {
      this.getProfilePlaylistsQueryRef = this.getProfilePlaylistsGQL.watch(
        {
          profileId: this.profileId,
          statuses: this.statuses,
          onlyParentPlaylist: true,
        },
        { fetchPolicy: 'cache-and-network' },
      );

      this.subs.sink = this.getProfilePlaylistsQueryRef.valueChanges.subscribe({
        next: ({ loading, data }) => {
          const { profile } = data;
          this.datatableLoading = loading;
          if (profile) {
            const visiblePlaylists = profile.playlists.filter(
              (x) => !this.hidePlaylistIds.includes(x.id),
            );
            const filteredPlaylist = this.filterService.filterListByName(
              this.searchText,
              visiblePlaylists,
            );
            this.total = filteredPlaylist.length;
            this.playlists = filteredPlaylist;
          } else {
            this.total = 0;
            this.playlists = [];
          }
        },
        error: (err) => {
          this.datatableLoading = false;
        },
      });
    } else {
      this.datatableLoading = false;
    }
  }

  onPageChange({ offset }: IDatatablePageChangeArgs) {
    this.page = offset;
  }

  navigateToPlaylist(playlist: Playlist) {
    if (playlist) {
      const id = this.encryptionService.encrypt(playlist.id);
      if (this.currentUserService.canManagePlaylist && id) {
        // this.router.navigate(['/playlist/manage', id],);
        // encrypt playlist id
        const encryptedPlaylistId = this.encryptionService.encrypt(playlist.id);

        // open route
        this.router.navigate(['playlists/manage', encryptedPlaylistId]);
      }
    } else {
      this.toasterService.error('UNKNOWN_ERROR');
    }
  }

  onRowClick(e: unknown) {
    const playlist = e as Playlist;
    if (this.redirectOnSelect) {
      this.slidePanelService.setPanelComponentId(playlist.id);
      this.navigateToPlaylist(playlist);
    }
  }

  openPlaylistManagePanel({ type, row, column }: IDatatableRowActivateArgs) {
    console.log('openPlaylistManagePanel', { type, row, column });

    if (
      column?.name !== '' &&
      type === DatatableRowActivationType.CLICK &&
      this.redirectOnSelect
    ) {
      const playlist: Playlist = row;
      if (playlist) {
        // set as current playlistId for playlist manage panel
        this.currentPlaylistId = playlist.id;

        // encrypt playlist id
        const encryptedPlaylistId = this.encryptionService.encrypt(playlist.id);

        // open route
        this.router.navigate(['playlists/manage', encryptedPlaylistId]);
        // this.router.navigate(['manage', encryptedPlaylistId], {
        //   relativeTo: this.route,
        // });
      } else {
        this.toasterService.error('UNKNOWN_ERROR');
      }
    }
  }

  search(value: string) {
    this.page = 1;
    this.searchText = value;
    this.getPlaylists();
  }

  navigateToPlaylistCreate(type: PlaylistType = PlaylistType.Scheduled) {
    const encryptedType = this.encryptionService.encrypt(type);
    this.router.navigate([`playlists/create`, encryptedType]);
  }

  bulkActionClick(action: string) {
    const ids = this.selectedPlaylists.map((x) => x.id);

    switch (action) {
      case PlaylistBulkAction.DeletePlaylists:
        this.deletePlaylists(ids);
        break;
      case PlaylistBulkAction.DuplicateSelected:
        this.duplicatePlaylists(ids);
        break;
      case PlaylistBulkAction.PublishSelected:
        this.publishPlaylists(ids);
        break;
      default:
        throw new Error(`Action not supported, ${action}`);
    }
    this.designageDataTable.selection.clear();
    this.selectedPlaylists = [];
  }

  publishPlaylists(ids: string[]) {
    const modalRef = this.modalService.open(PlaylistPublishDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.profileId = this.profileId;
    modalRef.componentInstance.showSaveButton = false;
    modalRef.componentInstance.showCreateButton = false;

    modalRef.result.then((value: IPlaylistSaveDialogResult) => {
      if (value.option === PlaylistSaveDialogCloseReason.PUBLISH) {
        this.publishSelectedPlaylists(ids, value);
      } else {
        throw new Error(`Unknown dialog close reason ${value.option}`);
      }
    });
  }

  duplicatePlaylists(ids: string[]) {
    this.subs.sink = this.duplicatePlaylistsGQL
      .mutate({ ids })
      .subscribe(({ data, loading }) => {
        if (data?.duplicatePlaylists.isSuccessful) {
          this.selectedPlaylists = [];
          this.getPlaylists();
          this.toasterService.clear();
          this.toasterService.success('DUPLICATE_PLAYLIST_SUCCESS');
        } else {
          this.toasterService.error('DUPLICATE_PLAYLIST_ERROR');
        }
      });
  }

  private deletePlaylists(ids: string[]) {
    this.bulkDelete(ids);
  }

  /** asks before delete all selected playlists */
  bulkDelete(ids: string[]) {
    const modal = this.modalService.open(DeletePlaylistDialogComponent, {
      backdrop: 'static',
    });
    modal.componentInstance.isDeleteAllSelected = ids.length > 1;
    modal.componentInstance.selectedPlaylists = this.selectedPlaylists;
    for (const playlist of this.selectedPlaylists) {
      modal.componentInstance.activeChannels = this.getActiveChannels(playlist);
      modal.componentInstance.name = playlist.name;
    }
    modal.result
      .then(async () => {
        this.toasterService.info('DELETING_PLAYLISTS');
        try {
          const success = await this.playlistService.deletePlaylists(ids);

          if (success) {
            this.toasterService.clear();
            this.toasterService.success('DELETE_PLAYLIST_SUCCESS');
          } else {
            this.toasterService.error('UNKNOWN_ERROR');
          }
        } catch (e) {
          const error = e as unknown as ApolloError;
          error.graphQLErrors.forEach((gqlError) => {
            console.error('bulkDelete', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
        }

        this.selectedPlaylists = [];
      })
      .catch(() => {})
      .finally(() => {
        this.selectedPlaylists = [];
        // this.getPlaylists();
        this.toasterService.clear();
      });
  }

  getActiveChannels(playlist: PlaylistForPlaylistListFragment) {
    let activeChannels: string[] = [];
    if (playlist?.childPlaylists?.length) {
      for (const child of playlist.childPlaylists) {
        if (child?.status === PlaylistStatus.Active) {
          activeChannels = [...new Set(child.channels.map((x) => x.name))];
          return activeChannels;
        }
      }
    }
    return activeChannels;
  }

  refreshPlaylists() {
    this.playlistService.refreshPlaylistsFromApi();
  }

  private publishSelectedPlaylists(
    ids: string[],
    playlistSaveDialogResult: IPlaylistSaveDialogResult,
  ) {
    this.subs.sink = this.bulkActionPlaylists
      .mutate({
        ids,
        action: PlaylistBulkAction.PublishSelected,
        input: {
          // channelGroupIds: playlistSaveDialogResult.channelGroups.map(
          //   (x) => x.id
          // ),
          channelIds: playlistSaveDialogResult.channels.map((x) => x.id),
          locationIds: playlistSaveDialogResult.locations.map((x) => x.id),
        },
      })
      .subscribe(({ data, loading }) => {
        this.toasterService.info('PUBLISHING_PLAYLISTS');
        if (data) {
          if (
            data.bulkActionPlaylists.updateResults.find((x) => !x.isSuccessful)
          ) {
            this.toasterService.warning('NOT_ALL_PLAYLISTS_ARE_PUBLISHED');
          } else {
            this.getPlaylists();
            this.toasterService.success('PUBLISH_PLAYLISTS_SUCCESS');
          }
        } else {
          this.toasterService.error('PUBLISHING_PLAYLISTS_FAILED');
        }
      });
  }

  onSelectedPlaylistsChange(playlists: PlaylistForPlaylistListFragment[]) {
    this.selectedPlaylists = playlists;
    this.playlistsSelect.emit(playlists as Playlist[]);
  }
}
