<div class="d-flex flex-column h-100">
  @if (showHeader || showAddPlaylistButton) {
    <div class="d-flex justify-content-between align-items-center">
      @if (showHeader) {
        <h4 class="flex-grow-1">
          {{
            channelId
              ? ('PLAYLISTS_CONNECTED_TO_THIS_CHANNEL' | translate)
              : ('PLAYLISTS_CONNECTED_TO_THIS_PROFILE' | translate)
          }}
        </h4>
      }
      @if (canAddPlaylists && showAddPlaylistButton) {
        <div class="text-end">
          <button
            class="btn btn-outline-primary"
            type="button"
            (click)="navigateToPlaylistCreate()"
          >
            {{ 'ADD_PLAYLIST' | translate }}
          </button>
          @if (isSuperAdmin) {
            <button
              class="ms-2 btn btn-outline-primary"
              type="button"
              (click)="navigateToPlaylistCreate(PlaylistType.Interactive)"
            >
              {{ 'ADD_PLAYLIST_INTERACTIVE' | translate }}
            </button>
          }
        </div>
      }
    </div>
  }

  <!-- <button (click)="refreshPlaylists()">refresh playlists</button> -->

  <!-- Playlist List -->
  <designage-data-table
    configId="playlist-list"
    [data]="playlistsSignal()"
    [columns]="desColumns"
    [loading]="loading()"
    [selectedRows]="selectedPlaylists"
    (selectedRowsChange)="onSelectedPlaylistsChange($event)"
    [alwaysSort]="true"
    [showBulkActions]="showBulkActionControl"
    [showFooter]="true"
    (rowClick)="onRowClick($event)"
    [customComponent]="[playlistPreview, playlistStatus, createdAt, updatedAt]"
    [showMultiSelect]="
      enableCheckbox && !(enableSlidePanel && isSlidePanelOpen())
    "
    [rowActiveInSlidingPanel]="selectedPlaylistId()"
  >
    <!-- Bulk Actions Template -->
    <ng-container ngProjectAs="bulkActions">
      @for (action of playlistListActions; track action) {
        <button
          class="btn btn-sm mx-2"
          [ngClass]="bulkActionsUtil.getButtonClass(action).class"
          [ngbTooltip]="
            bulkActionsUtil.getButtonClass(action).toolTip || '' | translate
          "
          (click)="bulkActionClick(action)"
        >
          {{ action | translate }}
        </button>
      }
    </ng-container>
  </designage-data-table>

  <!-- No Playlists -->
  <div
    [class.hidden]="datatableLoading || loading || !hasNoData"
    class="col-12"
  >
    <hr />
    <h4 class="unavailable-message">
      {{ 'NO_PLAYLISTS_FOUND' | translate }}
    </h4>
  </div>
</div>
<!-- <div class="d-flex flex-column overflow-hidden h-100">
</div> -->
<ng-template #playlistPreview let-row>
  @switch (row.previewContent?.type) {
    @case ('IMAGE') {
      <cl-media
        [ngbPopover]="popThumbnailContent"
        triggers="mouseenter:mouseleave"
        container="body"
        placement="end auto"
        ngbTooltip="{{ row.name }}"
        class="cl-media-image"
        [public-id]="row.previewContent?.publicId"
        mediaType="image"
        width="50"
        height="50"
        resizeMethod="limit"
        quality="auto"
        fetch-format="avif"
      ></cl-media>
    }
    @case ('VIDEO') {
      <cl-media
        [ngbPopover]="popThumbnailContent"
        triggers="mouseenter:mouseleave"
        container="body"
        placement="end auto"
        ngbTooltip="{{ row.name }}"
        class="cl-media-video"
        [public-id]="row.previewContent?.publicId"
        mediaType="videoPoster"
        width="50"
        height="50"
        resizeMethod="limit"
        quality="auto"
        fetch-format="avif"
      ></cl-media>
    }
    @default {
      <img
        width="30"
        height="30"
        alt="Designage Widget"
        src="assets/images/DesignageWidgetIcon.svg"
        class="icon"
      />
    }
  }
  <ng-template #popThumbnailContent>
    @switch (row.previewContent.type) {
      @case ('IMAGE') {
        <cl-media
          [ngbPopover]="popThumbnailContent"
          triggers="mouseenter:mouseleave"
          container="body"
          placement="end auto"
          ngbTooltip="{{ row.name }}"
          class="cl-media-image"
          [public-id]="row.previewContent.publicId"
          mediaType="image"
          width="250"
          height="250"
          resizeMethod="limit"
          quality="auto"
          fetch-format="avif"
        ></cl-media>
      }
      @case ('VIDEO') {
        <cl-media
          [ngbPopover]="popThumbnailContent"
          triggers="mouseenter:mouseleave"
          container="body"
          placement="end auto"
          ngbTooltip="{{ row.name }}"
          class="cl-media-video"
          [public-id]="row.previewContent.publicId"
          mediaType="videoPoster"
          width="250"
          height="250"
          resizeMethod="limit"
          quality="auto"
          fetch-format="avif"
        ></cl-media>
      }
      @default {
        <img
          alt="Designage Widget"
          src="assets/images/DesignageWidgetIcon.svg"
          class="icon"
        />
      }
    }
  </ng-template>
</ng-template>

<ng-template #createdAt let-row>
  <table-dateTime
    [data]="row.createdAt"
    [tooltipPipeArgs]="'medium'"
  ></table-dateTime>
</ng-template>
<ng-template #updatedAt let-row>
  <table-dateTime
    [data]="row.updatedAt"
    [tooltipPipeArgs]="'medium'"
  ></table-dateTime>
</ng-template>

<ng-template #playlistStatus let-row>
  <span
    class="badge"
    [class.bg-success]="row.status === 'ACTIVE'"
    [class.bg-primary]="row.status === 'PUBLISHED'"
    [class.bg-warning]="
      row.status === 'DRAFT' || row.status === 'READY_TO_PUBLISH'
    "
    [class.bg-danger]="row.status === 'NOT_SAVED'"
    [class.bg-secondary]="row.status === 'ARCHIVED'"
    >{{ row.status | translate }}</span
  >
</ng-template>

<!-- Playlist Manage Panel -->
@if (enableSlidePanel) {
  <router-outlet></router-outlet>
}
