import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  GetProfileChannelsForChannelGroupGQL,
  GetProfileChannelsForChannelGroupQuery,
  Maybe,
  GetProfileChannelsLocationsChannelGroupsForChannelSelectionGQL,
  GetProfileChannelsLocationsChannelGroupsForChannelSelectionQuery,
  PlaylistStatus,
  ChannelToPlaylistFragment,
} from '@designage/gql';
import {
  IDatatableRowActivateArgs,
  IDatatablePageChangeArgs,
  IDesignageDataTableColumns,
} from '@desquare/interfaces';
import { SubSink } from 'subsink';
import { FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import { CurrentUserService, PlaylistEditorService } from '@desquare/services';
import { ChannelSelectionMode, ChannelFilters } from '@desquare/enums';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlaylistListDialogComponent } from '@designage/app/playlist/playlist-list-dialog/playlist-list-dialog.component';
import { ExcludeMaybe } from '@desquare/types';
import { domConstants } from '@desquare/constants';
import { TranslateModule } from '@ngx-translate/core';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';
import { TableDateTimeComponent } from '@desquare/components/common/src/designage-data-table/table-components/table-dateTime.component';

type RowValue = ChannelRow | LocationRow | ChannelGroupRow;
type ChannelRow = {
  id: Maybe<string>;
  name: Maybe<string>;
  __typename?: Maybe<string>;
  region?: Maybe<string>;
};
type LocationRow = ExcludeMaybe<
  GetProfileChannelsLocationsChannelGroupsForChannelSelectionQuery['profile']
>['locations'][number];
type ChannelGroupRow = ExcludeMaybe<
  GetProfileChannelsLocationsChannelGroupsForChannelSelectionQuery['profile']
>['channelGroups'][number];

@Component({
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    DesignageDataTableComponent,
    TableDateTimeComponent,
  ],
  selector: 'app-channel-selection',
  template: `<div class="pb-4 h-100 d-flex flex-column">
    @if (channelFilterEnabled) {
      <select
        (change)="setChannelFilter($event)"
        class="me-2 search-category form-select form-select-sm form-select-dark"
      >
        <option [ngValue]="null" disabled>
          {{ 'SELECT_CATEGORY' | translate }}
        </option>
        @for (filter of channelFilters; track filter) {
          <option [value]="filter">
            {{ filter | translate }}
          </option>
        }
      </select>
    }

    <designage-data-table
      configId="channel-selection"
      [data]="rowValue"
      [columns]="desColumns"
      [loading]="loading"
      [alwaysSort]="true"
      [selectedRows]="selected"
      [customComponent]="[createdAt]"
      (selectedRowsChange)="updateSelectedItems($event)"
      [showMultiSelect]="true"
    ></designage-data-table>

    <ng-template #createdAt let-row>
      <table-dateTime
        [data]="row.createdAt"
        [tooltipPipeArgs]="'medium'"
      ></table-dateTime>
    </ng-template>
  </div> `,
  styleUrls: ['./channel-selection.component.scss'],
})
export class ChannelSelectionComponent implements OnInit, OnChanges, OnDestroy {
  private _selectedChannels: ChannelRow[] = [];
  private _selectedLocations: LocationRow[] = [];
  private _selectedChannelGroups: ChannelGroupRow[] = [];

  private subs = new SubSink();

  _profileId!: string;
  @Input()
  get profileId() {
    return this.currentUserService.currentProfile?.id!;
    // return this._profileId;
  }
  set profileId(value: string) {
    this._profileId = value;
  }
  @Input()
  get selectedChannels() {
    return this._selectedChannels;
  }
  set selectedChannels(channels: ChannelRow[]) {
    this._selectedChannels = channels;
    this.setTableValues();
  }

  @Input()
  get selectedLocations() {
    return this._selectedLocations;
  }
  set selectedLocations(locations: LocationRow[]) {
    this._selectedLocations = locations;
  }

  @Input()
  get selectedChannelGroups() {
    return this._selectedChannelGroups;
  }
  set selectedChannelGroups(channelGroups: ChannelGroupRow[]) {
    this._selectedChannelGroups = channelGroups;
  }

  @Input() enableCheckbox = false;
  @Input() channelSelectionMode!: ChannelSelectionMode;
  @Output() selectedChannelsChange = new EventEmitter<ChannelRow[]>();
  @Output() selectedLocationsChange = new EventEmitter<LocationRow[]>();
  @Output() selectedChannelGroupsChange = new EventEmitter<ChannelGroupRow[]>();
  @Output() selectedChannelFilterChange = new EventEmitter<string>();
  @Output() rowActivate = new EventEmitter<IDatatableRowActivateArgs>();

  page = 1;
  pageSize = 10;
  total = 0;
  loading = false;
  searchText = '';
  pageSizeOptions = domConstants.DATA_PAGE_SIZE_OPTIONS;

  desColumns!: IDesignageDataTableColumns[];
  initialLoad = true;
  channelSearchForm!: FormGroup;

  locations: LocationRow[] = [];
  channels: ChannelRow[] = [];
  userChannels: ChannelRow[] = [];
  channelGroups: ChannelGroupRow[] = [];
  statuses: PlaylistStatus[] = [
    PlaylistStatus.Draft,
    PlaylistStatus.Published,
    PlaylistStatus.ReadyToPublish,
  ];
  selectedLocationId!: Maybe<string>;
  channelFilters = Object.keys(ChannelFilters);
  selectedChannelFilter!: Maybe<string>;
  rowValue: RowValue[] = [];
  selectedValues: RowValue[] = [];

  constructor(
    private getProfileChannelsLocationsChannelGroupsGQL: GetProfileChannelsLocationsChannelGroupsForChannelSelectionGQL,
    private getProfileChannelsForChannelGroupGQL: GetProfileChannelsForChannelGroupGQL,
    private formBuilder: FormBuilder,
    private currentUserService: CurrentUserService,
    private modalService: NgbModal,
    private playlistEditorService: PlaylistEditorService,
  ) {}

  get locationFilterEnabled() {
    return this.selectedChannelFilter === ChannelFilters.CHANNELS;
  }

  get channelFilterEnabled() {
    return this.channelSelectionMode === ChannelSelectionMode.PUBLISHING;
  }

  get locationId() {
    return this.selectedLocationId ? this.selectedLocationId : undefined;
  }

  get enableCheckBox() {
    if (!this.selectedChannelFilter) {
      return null;
    }
    return [
      ChannelFilters.CHANNELS.toString(),
      ChannelFilters.LOCATIONS.toString(),
    ].includes(this.selectedChannelFilter);
  }

  get selected() {
    if (this.channelSelectionMode === ChannelSelectionMode.CHANNEL_GROUP) {
      return this.selectedChannels;
    } else {
      return this.selectedValues;
    }
  }

  ngOnInit() {
    this.initVariables();
    this.initForm();
    this.setFormState();
    this.setTableValues();
    this.initSubscriptions();
  }

  initForm() {
    this.channelSearchForm = this.formBuilder.group({
      search: [null],
      pageSize: [this.pageSize],
      channelFilter: [null],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.profileId) {
      this.getChannelsLocationsChannelGroups();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  setFormState() {
    this.subs.sink =
      this.channelSearchForm.controls.pageSize.valueChanges.subscribe(
        (value: number) => {
          this.pageSize = value;
          this.currentUserService.setPreferredPageSize(value);

          this.page = 1;
        },
      );
  }

  initSubscriptions() {
    this.subs.sink = this.playlistEditorService.playlistSaveChanges.subscribe(
      (value: boolean) => {
        if (value) {
          this.getChannelsLocationsChannelGroups();
        }
      },
    );
  }

  initVariables() {
    this.initColumns();
    this.pageSize = this.currentUserService.preferredPageSize;
    this.selectedLocationId = null;
    this.selectedChannelFilter = ChannelFilters.CHANNELS;
    this.selectedChannelFilterChange.emit(this.selectedChannelFilter);
  }

  getChannelsLocationsChannelGroups() {
    if (this.profileId && !this.loading) {
      this.loading = true;
      this.channels = [];
      this.userChannels = [];
      if (this.channelSelectionMode === ChannelSelectionMode.PUBLISHING) {
        this.subs.sink = this.getProfileChannelsLocationsChannelGroupsGQL
          .fetch(
            {
              profileId: this.currentUserService.currentProfile?.id!,
              userId: this.currentUserService.userId || '',
              statuses: this.statuses,
              channelGroupName: this.searchText,
              locationName: this.searchText,
              channelName: this.searchText,
            },
            { fetchPolicy: 'network-only' },
          )
          .subscribe(({ loading, data }) => {
            this.loading = loading;

            const userChannels = data.profile?.users?.length
              ? data.profile?.users[0].channels?.filter(
                  (x) =>
                    x.profile?.id ===
                    this.currentUserService.currentProfile?.id,
                ) || []
              : [];
            if (
              !this.currentUserService.isSuperAdmin &&
              !this.currentUserService.roles?.includes('Profile manager') &&
              userChannels.length > 0
            ) {
              this.channels = data.profile?.users[0].channels || [];
            } else {
              this.channels = data.profile?.channels || [];
            }
            this.selectedChannels.forEach((sc) => {
              const c = this.channels.find((x) => x.id === sc.id);
              if (c) {
                c.region = sc.region;
              }
            });
            this.channelGroups = data.profile?.channelGroups || [];
            this.locations = data.profile?.locations || [];
            this.setTableValues();

            if (this.locationId) {
              // TODO: refactor this since channel.location moved to device.location
              // this.rowValue = this.channels.filter(
              //   (x) => x && x.locationId === this.locationId
              // );
            }

            this.total = this.rowValue.length;
          });
      }

      if (this.channelSelectionMode === ChannelSelectionMode.CHANNEL_GROUP) {
        this.subs.sink = this.getProfileChannelsForChannelGroupGQL
          .fetch(
            {
              profileId: this.profileId,
              name: this.searchText,
            },
            { fetchPolicy: 'network-only' },
          )
          .subscribe(({ loading, data }) => {
            this.loading = loading;
            if (data.profile && data.profile.channels) {
              this.channels = data.profile.channels;
              this.rowValue = this.channels;
              this.total = this.channels.length;
            } else {
              this.channels = [];
              this.total = 0;
            }
          });
      }
    }
  }

  initColumns() {
    if (this.channelSelectionMode === ChannelSelectionMode.PUBLISHING) {
      this.desColumns = [
        {
          fieldName: 'name',
          name: 'NAME',
          type: 'string',
          visible: 'mandatory',
        },
        {
          fieldName: 'playlists.length',
          name: 'NUMBER_OF_PLAYLISTS',
          type: 'string',
          visible: 'mandatory',
        },
      ];
    }
    if (this.channelSelectionMode === ChannelSelectionMode.CHANNEL_GROUP) {
      this.desColumns = [
        {
          fieldName: 'name',
          name: 'NAME',
          type: 'string',
          visible: 'mandatory',
        },
        {
          fieldName: 'description',
          name: 'DESCRIPTION',
          type: 'string',
          visible: false,
        },
        {
          fieldName: 'location.name',
          name: 'LOCATION',
          type: 'string',
          visible: true,
        },
        {
          fieldName: 'createdAt',
          name: 'CREATED_ON',
          type: 'template',
          templateRef: 'updatedAt',
          visible: true,
        },
      ];
    }
  }

  onPageChange(event: IDatatablePageChangeArgs) {
    this.page = event.offset;
  }

  emitSelectedItems() {
    this.selectedLocationsChange.emit(this.selectedLocations);
    this.selectedChannelsChange.emit(this.selectedChannels);
    this.selectedChannelGroupsChange.emit(this.selectedChannelGroups);
  }

  updateSelectedItems(rows: RowValue[]) {
    this.selectedChannels = [];
    this.selectedChannelGroups = [];
    this.selectedLocations = [];

    for (const row of rows) {
      switch (row.__typename) {
        case 'Channel':
          this.selectedChannels.push(row);
          break;
        case 'ChannelGroup':
          // TODO: uncomment this
          // this.selectedChannelGroups.push(row);
          break;
        case 'Location':
          // TODO: uncomment this
          //this.selectedLocations.push(row);
          break;
      }
    }

    this.emitSelectedItems();
  }

  search(value: string) {
    this.page = 1;
    this.searchText = value;

    this.getChannelsLocationsChannelGroups();
  }

  setLocationId(id: string) {
    if (id) {
      this.selectedLocationId = id;
    } else {
      this.selectedLocationId = null;
    }

    this.getChannelsLocationsChannelGroups();
  }

  setChannelFilter(e: Event) {
    const value = (e.target as HTMLSelectElement).value;
    this.selectedValues = [];
    this.selectedChannelFilter = value;
    this.selectedChannelFilterChange.emit(value);
    this.setTableValues();

    this.channelSearchForm.patchValue({
      search: null,
    });

    this.selectedLocationId = null;

    this.getChannelsLocationsChannelGroups();
    this.initColumns();
  }

  setTableValues() {
    this.rowValue = [];

    switch (this.selectedChannelFilter) {
      case ChannelFilters.CHANNELS:
        this.rowValue = this.channels;
        this.selectedValues = this.selectedChannels;
        break;
      case ChannelFilters.LOCATIONS:
        if (this.locations) {
          this.rowValue = this.locations;
          this.selectedValues = this.selectedLocations;
        }
        break;
        // case ChannelFilters.CHANNEL_GROUPS:
        //   if (this.channelGroups) {
        //     this.rowValue = this.channelGroups;
        //     this.selectedValues = this.selectedChannelGroups;
        //   }
        break;
    }
  }

  showPlaylistList(row: RowValue) {
    const modalRef = this.modalService.open(PlaylistListDialogComponent, {
      backdrop: 'static',
      windowClass: 'cesdk-modal',
    });

    if (row.__typename === 'Channel') {
      modalRef.componentInstance.channelId = row.id;
    } else if (row.__typename === 'Location') {
      modalRef.componentInstance.locationId = row.id;
    } else if (row.__typename === 'ChannelGroup') {
      modalRef.componentInstance.channelGroupId = row.id;
    }
  }
}
